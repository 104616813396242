import makeStyles from "@mui/styles/makeStyles";

const useAccordionStyles = makeStyles((theme) => ({
  root: {
    border: "none",
    background: theme.palette.navigation.background,
    color: theme.palette.navigation.text,
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&.Mui-expanded": {
      marginTop: 0,
    },
  },
  summary: {
    background: theme.palette.navigation.background,
    borderBottom: "none",
    minHeight: 48,
    "&.Mui-expanded": {
      minHeight: 48,
    },
  },
  summaryContent: {
    alignItems: "center",
    "&.Mui-expanded": {
      margin: 0,
    },
  },
  summaryIcon: {
    color: "#fff",
  },
  details: {
    padding: 0,
  },
  currentRoute: {
    color: "#65b2b9",
  },
}));

export default useAccordionStyles;
