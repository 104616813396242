import React, { Suspense, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import { styled } from "@mui/material/styles";
import { Route, Redirect, Switch } from "react-router-dom";
import MicroClient from "./MicroClient";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Hidden from "@mui/material/Hidden";
import Typography from "@mui/material/Typography";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import AccountProvider, { useAccountDetails } from "./useAccountDetails";
import ModulesProvider, { useActiveModules } from "./useActiveModules";
import ResponsiveDrawer from "./Components/ResponsiveDrawer";
import { ErrorBoundary } from "react-error-boundary";
import LoadingSpinner from "../CommonComponents/UI/LoadingSpinner";
import ErrorScreen from "../CommonComponents/UI/ErrorScreen";
import Button from "../CommonComponents/UI/Button";

const PREFIX = "IndexContent";

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  contentShift: `${PREFIX}-contentShift`,
  icon: `${PREFIX}-icon`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    height: "100vh",
    "@media print": {
      colorAdjust: "exact",
    },
  },

  [`& .${classes.content}`]: {
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - 256px)`,
    },
    [theme.breakpoints.up("xl")]: {
      width: `calc(100% - 296px)`,
    },
  },

  [`& .${classes.contentShift}`]: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },

  [`& .${classes.icon}`]: {
    display: "inline-block",
    position: "fixed",
    top: 1,
    left: 10,
    zIndex: 10,
    padding: 12,
    height: 48,
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
    "@media print": {
      display: "none",
    },
  },
}));

const ModuleRoute = ({ path, clientName, testClient: TestClient }) => {
  const { current: Module } = React.useRef(
    <MicroClient clientName={clientName} />
  );
  // TestClient avoids lazy loading as Cypress doesn't handle multiple chunks well as it only serves a single bundle
  // https://github.com/cypress-io/cypress/issues/9711
  return (
    <Route
      path={path}
      render={() =>
        TestClient ? <TestClient clientName={clientName} /> : Module
      }
    />
  );
};

const InnerApp = ({ testClient }) => {
  const { info } = useAccountDetails();
  const { modules } = useActiveModules();

  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => setOpen((open) => !open);

  const contentClasses = open
    ? `${classes.content} ${classes.contentShift}`
    : classes.content;

  return (
    <>
      <ResponsiveDrawer
        modules={modules}
        user={info}
        open={open}
        toggleDrawer={toggleDrawer}
      />
      <main className={contentClasses}>
        <Suspense fallback={<LoadingSpinner sx={{ justifySelf: "center" }} />}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            edge="start"
            className={classes.icon}
          >
            <MenuIcon />
          </IconButton>
          <ErrorBoundary
            FallbackComponent={() => (
              <ErrorScreen
                message={() => (
                  <>
                    <Hidden lgUp>
                      <Typography>
                        Use the navigation menu to help find what you're looking
                        for, if the problem persists, please contact us.
                      </Typography>
                    </Hidden>
                    <Hidden lgDown>
                      <Typography>
                        Use the navigation on the left to help find what you're
                        looking for, if the problem persists, please contact us.
                      </Typography>
                    </Hidden>
                  </>
                )}
              />
            )}
          >
            <Switch>
              {modules.map(({ baseRoute, name }) => (
                <ModuleRoute
                  key={baseRoute}
                  clientName={name}
                  path={baseRoute}
                  testClient={testClient}
                />
              ))}
              {modules.length ? <Redirect to={modules[0].baseRoute} /> : null}
            </Switch>
          </ErrorBoundary>
        </Suspense>
      </main>
    </>
  );
};

const LaunchDarklyProvider = ({ children }) => {
  const [LDProvider, setLDProvider] = useState(null);
  const { info } = useAccountDetails();

  useEffect(() => {
    const { LAUNCH_DARKLY_CLIENT_ID } = window._env_;

    const fetchLD = async () => {
      const LDResult = await asyncWithLDProvider({
        clientSideID: LAUNCH_DARKLY_CLIENT_ID,
        user: {
          key: info.sub || info.farm.sub,
          custom: {
            farm: info.farm.name,
          },
        },
      });
      setLDProvider(() => LDResult);
    };

    fetchLD();
  }, []); // only mount

  if (!LDProvider) return <LoadingSpinner />;

  return <LDProvider>{children}</LDProvider>;
};

const IndexContent = ({ testClient }) => {
  const { logout } = useAuth0();

  const onError = () => logout({ returnTo: window.location.origin });

  return (
    <Root className={classes.root}>
      <Suspense fallback={<LoadingSpinner />}>
        <ErrorBoundary
          FallbackComponent={() => (
            <ErrorScreen
              withHeader={false}
              message="There seems to be a problem accessing your profile. Are you logged into the correct farm?"
            >
              <Button
                variant="outlined"
                title="Try Login Again"
                onClick={onError}
              />
            </ErrorScreen>
          )}
        >
          <AccountProvider>
            <LaunchDarklyProvider>
              <ModulesProvider>
                <InnerApp testClient={testClient} />
              </ModulesProvider>
            </LaunchDarklyProvider>
          </AccountProvider>
        </ErrorBoundary>
      </Suspense>
    </Root>
  );
};

export default IndexContent;

IndexContent.propTypes = {
  testClient: PropTypes.elementType,
};

IndexContent.defaultProps = {
  testClient: null,
};
