import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

// TODO: Investigate can we add this under the suspense
// architecture so it's all captured by an initial loading icon
const SetupAxios = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const setAxiosInterceptors = async () => {
      if (
        getAccessTokenSilently === null ||
        getAccessTokenSilently === undefined
      ) {
        return;
      }
      const token = await getAccessTokenSilently();
      axios.interceptors.request.use((config) => {
        config.headers.common.Authorization = `Bearer ${token}`;
        config.headers.common["Access-Control-Allow-Origin"] = "*";
        return config;
      });
      setLoading(false);
    };
    setAxiosInterceptors();
  }, [getAccessTokenSilently]);
  if (loading) return <div></div>;
  return children;
};

export default SetupAxios;
