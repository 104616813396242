import React from "react";
import PropTypes from "prop-types";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "../CommonComponents/UI/theme";
import IndexContent from "./IndexContent";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
});

const App = ({ testClient }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <IndexContent testClient={testClient} />
        </ThemeProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
  );
};

export default App;

IndexContent.propTypes = {
  testClient: PropTypes.elementType,
};

IndexContent.defaultProps = {
  testClient: null,
};
