import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";

const PREFIX = "LoadingSpinner";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(() => ({
  [`&.${classes.root}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const LoadingSpinner = (props) => {
  return (
    <Root
      className={classes.root}
      sx={{
        height: props.size || "100%",
        width: props.size || "100%",
      }}
    >
      <CircularProgress {...props} />
    </Root>
  );
};

LoadingSpinner.propTypes = {
  classes: PropTypes.object,
  color: PropTypes.oneOf(["inherit", "primary", "secondary"]),
  disableShrink: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  thickness: PropTypes.number,
  value: PropTypes.number,
  variant: PropTypes.oneOf(["determinate", "indeterminate", "static"]),
};

export default LoadingSpinner;
