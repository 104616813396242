import { createBrowserHistory } from "history";

const history = createBrowserHistory();
let prevPath = null;

// listen and notify analytics tools of a page change
history.listen((location) => {
  if (location.pathname !== prevPath) {
    prevPath = location.pathname;

    window.gtag("event", "page_view", {
      page_location: location.pathname,
    });
  }
});

export default history;
