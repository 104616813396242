import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Header from "./Header";

const PREFIX = "ErrorScreen";

const classes = {
  container: `${PREFIX}-container`,
  paper: `${PREFIX}-paper`,
};

const Container = styled("div")(({ theme }) => ({
  [`& .${classes.container}`]: {
    width: "100%",
    height: "100%",
    padding: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },

  [`& .${classes.paper}`]: {
    height: 180,
    width: "100%",
    maxWidth: 600,
    padding: 30,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
}));

const ErrorScreen = ({ message, withHeader = true, children }) => {
  const renderMessage = typeof message === "function";
  return (
    <>
      {withHeader ? <Header title="Error" /> : null}
      <Container className={classes.container}>
        <Paper className={classes.paper}>
          <Typography variant="h5">Something went wrong</Typography>
          {renderMessage ? message() : <Typography>{message}</Typography>}
          {children}
        </Paper>
      </Container>
    </>
  );
};

export default ErrorScreen;
