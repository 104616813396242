import { createTheme } from "@mui/material/styles";

export const colors = {
  green: "#008389",
  white: "#FFFFFF",
  black: "rgba(0, 0, 0, 0.87)",
  charcoal: "#333333",
  gray: "rgba(0, 0, 0, 0.54)",
  gray64: "#a3a3a3",
  red: "#e31a1c",
  cleanout: "#e69e6c",
  delivery: "#a47af4",
  // colours for scheduled events on feed events screen
  cleanouts: "#e69e6c",
  deliveries: "#a47af4",
  pushups: "#00bfff",
  milking: "#fd93da",
  dataMissing: "#e9e9e9",
  labelGray: "#888888",
  snowGray: "#fafafa",
  mercuryGray: "#e9e9e9",
  whiteSmoke: "#f6f6f6",
  gray35: "#595959",
  gray88: "#e0e0e0",
  iconRed: "#ed5f5a",
  iconGreen: "#81caa1",
  iconOrange: "#ffb07b",
  selectedGreen: "#D1E7E9",
  tableProblem: "#F97D7C",
  atomic: "#374851",
  tooltipHrBlue: "#5d7b84",
  chartColumnGreen: "#a7dabe",
  chartColumnRed: "#ef716c",
  teal: "#A9D5E0",
  silverTreeGreen: "#74B591",
  valenciaRed: "#D34540",
};

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0, // phone
      sm: 600, // phone landscape
      md: 1024, // tablet
      lg: 1280, // laptop
      xl: 1440, // desktop
      xxl: 1920, // desktop large
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: { root: { backgroundImage: "unset" } },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "rgba(0, 0, 0, 0.08)",
            "&:hover": {
              background: "rgba(0, 0, 0, 0.08)",
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "rgb(55, 72, 81)",
          padding: "8px 12px",
          fontSize: 14,
        },
      },
    },
  },
  spacing: (value) => value * 8,
  typography: {
    fontFamily: "Noto, sans-serif",
    color: colors.black,
    h1: {
      fontSize: 48,
    },
    h2: {
      fontSize: 40,
    },
    h3: {
      fontSize: 32,
    },
    h4: {
      fontSize: 24,
    },
    h5: {
      fontSize: 20,
    },
    h6: {
      fontSize: 16,
    },
    subtitle1: {
      fontSize: 16,
    },
    subtitle2: {
      fontSize: 14,
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
    },
    caption: {
      fontSize: 12,
      color: colors.gray,
    },
  },
  palette: {
    primary: {
      main: colors.green,
    },
    secondary: {
      main: colors.white,
    },
    navigation: {
      background: colors.atomic,
      text: colors.snowGray,
    },
    error: {
      main: colors.red,
    },
    events: colors,
  },
  mixins: {
    toolbar: {},
  },
});

theme.overrides = {
  typography: {
    fontFamily: "Noto, sans-serif",
    color: colors.black,
    h1: {
      fontSize: 48,
      [theme.breakpoints.up("xl")]: {
        fontSize: 60,
      },
    },
    h2: {
      fontSize: 40,
      [theme.breakpoints.up("xl")]: {
        fontSize: 48,
      },
    },
    h3: {
      fontSize: 32,
      [theme.breakpoints.up("xl")]: {
        fontSize: 40,
      },
    },
    h4: {
      fontSize: 24,
      [theme.breakpoints.up("xl")]: {
        fontSize: 32,
      },
    },
    h5: {
      fontSize: 20,
      [theme.breakpoints.up("xl")]: {
        fontSize: 24,
      },
    },
    h6: {
      fontSize: 16,
      [theme.breakpoints.up("xl")]: {
        fontSize: 20,
      },
    },
    subtitle1: {
      fontSize: 16,
      [theme.breakpoints.up("xl")]: {
        fontSize: 20,
      },
    },
    subtitle2: {
      fontSize: 14,
      [theme.breakpoints.up("xl")]: {
        fontSize: 16,
      },
    },
    body1: {
      fontSize: 16,
      [theme.breakpoints.up("xl")]: {
        fontSize: 20,
      },
    },
    body2: {
      fontSize: 14,
      [theme.breakpoints.up("xl")]: {
        fontSize: 16,
      },
    },
    caption: {
      fontSize: 12,
      color: colors.gray,
      [theme.breakpoints.up("xl")]: {
        fontSize: 14,
      },
    },
  },

  mixins: {
    toolbar: {
      minHeight: 96,
      marginLeft: 15,
      [theme.breakpoints.up("lg")]: {
        minHeight: 96,
        marginLeft: 0,
      },
    },
  },
};

export default theme;
