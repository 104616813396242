import React, { useContext } from "react";
import { useQuery } from "react-query";
import { useFlags } from "launchdarkly-react-client-sdk";
import { fetchProfile } from "./services/profile";
import { getModulesAndFeaturesFromSubscriptions } from "./getModulesFromSubscriptions";
import * as permissions from "../constants/permissions";

const ModulesContext = React.createContext();

export const useActiveModules = () => useContext(ModulesContext);

const ModulesProvider = ({ children }) => {
  const flags = useFlags();

  const { data } = useQuery("profile", fetchProfile, {
    refetchOnWindowFocus: false,
  });

  const currentFarm = data.farms[0];

  // MAIN MODULES
  const alwaysEnabledModules = [
    "farmDashboard",
    "feedbunkManagement",
    "peopleProtocols",
    "reports",
    "languagesMenu",
  ];
  alwaysEnabledModules.forEach((flagId) => flags[flagId] = true);

  // Modules still managed by LaunchDarkly feature flag must be added to this array
  const flaggedModules = [
    "herdProductivity",
    "herdWelfare",
  ].filter((feature) => flags[feature]);

  const subscribedModules = [
    ...alwaysEnabledModules,
    ...flaggedModules,
  ];

  // SUB-MODULES
  const alwaysActiveFeatures = [
    // under `feedbunkManagement` main module
    "feedAvailability",
    "pens",
    // under `peopleProtocols` main module
    "feedingOperations",
    // under `settings` main module
    "accounts",
    "alerts",
    "targets",
  ];
  alwaysActiveFeatures.forEach((flagId) => flags[flagId] = true);

  // Features that are behind a LaunchDarkly feature flag must be added to this array
  const flaggedFeatures = [
    // under peopleProtocols
    "milkingOperations",
    // others
    "cowCountStockingDensity",
  ].filter((feature) => flags[feature]);

  const activeFeatures = [
    ...alwaysActiveFeatures,
    ...flaggedFeatures,
  ];

  if (currentFarm.pushups_enabled) {
    activeFeatures.push("pushups");
  }

  if (!data.permissions.includes(permissions.READ_ONLY_ROLE)) {
    subscribedModules.push("settingsModuleMenu");
    subscribedModules.push("settings");
  }

  const modules = getModulesAndFeaturesFromSubscriptions(subscribedModules, activeFeatures);
  const sections = React.useMemo(
    () => ({
      modules,
      features: activeFeatures,
      flags,
    }),
    []
  );

  return (
    <ModulesContext.Provider value={sections}>
      {children}
    </ModulesContext.Provider>
  );
};

export default ModulesProvider;
