import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App/index";
import { Auth0Provider } from "@auth0/auth0-react";
import { Router } from "react-router-dom";
import ProtectComponent from "./CommonComponents/Auth/ProtectComponent";
import SetupAxiosWithBearer from "./CommonComponents/Auth/SetUpAxiosWithBearer";
import history from "./history";
import "./i18n/config";

if (
  window._env_.ENVIRONMENT === "alus-development" ||
  window._env_.ENVIRONMENT === "local"
) {
  const { worker } = require("./mocks/browser");
  worker.start();
}

const onRedirectCallback = ({ returnTo = "/" }) => {
  history.replace(returnTo);
};

// TODO This need to be moved into a configuration file
// for speed keep it as is for now.
const { AUTH0 } = window._env_;

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={AUTH0.DOMAIN}
      clientId={AUTH0.CLIENT_ID}
      redirectUri={AUTH0.REDIRECT_URI}
      audience={AUTH0.AUDIENCE}
      scope={AUTH0.SCOPE}
      onRedirectCallback={onRedirectCallback}
    >
      <ProtectComponent
        component={() => (
          <Router history={history}>
            <SetupAxiosWithBearer>
              <App />
            </SetupAxiosWithBearer>
          </Router>
        )}
      />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
