import React, { useContext, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";
import { fetchProfile } from "./services/profile";

const AccountContext = React.createContext();

export const useAccountDetails = () => useContext(AccountContext);

const deprecatedTimezones = {
  "US/Pacific": "America/Los_Angeles",
  "US/Central": "America/Chicago",
  "US/Eastern": "America/New_York",
};

const AccountProvider = (props) => {
  const { user } = useAuth0();
  const { data, isLoading } = useQuery("profile", fetchProfile, {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isLoading) return;

    const farm = data.farms[0];

    if (user) {
      window.pendo.initialize({
        visitor: {
          id: user.email,
        },
        account: { ...farm, pens: farm.pens.length },
      });

      window.gtag("config", window._env_.GOOGLE_ANALYTICS_ID, {
        custom_map: {
          dimension1: farm.name,
          dimension2: user.sub,
          dimension3: farm.timezone,
        },
      });

      window.FS.identify(user.sub, {
        farmName_str: farm.name,
        email: user.email,
        cowCount_real: farm.approximate_cows_count,
        cowBreeds_str: farm.cow_breeds,
        penCount_real: farm.pens.length,
        timezone_str: farm.timezone,
      });
    }
  }, [data, isLoading, user]);

  // replace deprecated timezones for running on Android devices
  const currentFarm = deprecatedTimezones[data.farms[0].timezone]
    ? {
        ...data.farms[0],
        timezone: deprecatedTimezones[data.farms[0].timezone],
      }
    : data.farms[0];

  const info = { ...user, farm: currentFarm };

  const account = {
    info,
    permissions: data.permissions,
  };

  return (
    <AccountContext.Provider value={account}>
      {props.children}
    </AccountContext.Provider>
  );
};

export default AccountProvider;
