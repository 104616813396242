import * as routes from "../constants/routes";
import SettingsIcon from "@mui/icons-material/Settings";
import LyingTimeIcon from "../CommonComponents/Icons/LyingTime";
import ReportsIcon from "../CommonComponents/Icons/Reports";
import DashboardIcon from "../CommonComponents/Icons/Dashboard";
import PeopleIcon from "../CommonComponents/Icons/People";
import WelfareIcon from "../CommonComponents/Icons/Welfare";
import Feed24Icon from "../CommonComponents/Icons/FeedOverTwentyFourHours";

const MODULE_MAP = [
  {
    name: "FarmDashboard",
    id: "farmDashboard",
    icon: DashboardIcon,
    baseRoute: routes.DASHBOARD,
    exact: true,
    translationKeyNavOnly: "farm-dashboard",
    translationKey: "farm-dashboard",
    flagId: "farmDashboard",
    subRoutes: [],
    // priority: 2,
  },

  {
    name: "FeedbunkManagement",
    id: "feedbunkManagement",
    icon: Feed24Icon,
    baseRoute: routes.FEEDBUNK_MGMT,
    exact: true,
    translationKeyNavOnly: "feedbunk-mgmt",
    translationKey: "feedbunk-management",
    flagId: "feedbunkManagement",
    subRoutes: [
      {
        path: "/live-feed-status",
        name: "24 Hour Feed Availability",
        id: "feedAvailability",
        translationKey: "live-feed-status",
      },
      {
        path: "/pen-summary",
        name: "Pens",
        id: "pens",
        translationKey: "pen-summary",
      },
    ],
    priority: 3,
  },
  {
    name: "PeopleProtocols",
    id: "peopleProtocols",
    icon: PeopleIcon,
    baseRoute: routes.PEOPLE_PROTOCOLS,
    exact: true,
    translationKeyNavOnly: "people-and-protocols",
    translationKey: "people-and-protocols",
    flagId: "peopleProtocols",
    subRoutes: [
      {
        path: "/feeding-operations",
        name: "Feeding Operations",
        id: "feedingOperations",
        translationKey: "feeding-operations",
      },
      {
        path: "/milking-operations",
        name: "Milking Operations",
        id: "milkingOperations",
        translationKey: "milking-operations",
      },
    ],
    priority: 4,
  },
  {
    name: "Settings",
    id: "settings",
    baseRoute: routes.SETTINGS,
    exact: false,
    icon: SettingsIcon,
    translationKey: "settings",
    flagId: "settingsModuleMenu",
    subRoutes: [
      {
        path: "/accounts",
        name: "User Accounts",
        id: "accounts",
        translationKey: "user-accounts",
      },
      {
        path: "/alerts",
        name: "Alerts",
        id: "alerts",
        translationKey: "alerts",
      },
      {
        path: "/targets",
        name: "Targets",
        id: "targets",
        translationKey: "targets",
      },
    ],
    priority: 6,
  },
  {
    name: "HerdProductivity",
    id: "herdProductivity",
    baseRoute: routes.HERDPRODUCTIVITY,
    exact: false,
    icon: LyingTimeIcon,
    translationKey: "herd-productivity",
    flagId: "herdProductivity",
    subRoutes: [],
    priority: 7,
  },
  {
    name: "HerdWelfare",
    id: "herdWelfare",
    baseRoute: routes.HERD_WELFARE,
    exact: false,
    icon: WelfareIcon,
    translationKey: "herd-welfare",
    flagId: "herdWelfare",
    subRoutes: [],
    priority: 8,
  },
  {
    name: "Reports",
    id: "reports",
    icon: ReportsIcon,
    baseRoute: routes.REPORTS,
    exact: true,
    translationKeyNavOnly: "reports",
    translationKey: "reports",
    flagId: "reports",
    subRoutes: [],
    priority: 5,
  },
];

/**
 * Function that returns a sorted list of Modules that are currently
 * active for a current subscription along with any active features (subroutes)
 *
 * @subscribed Array of modules that the user is subscribed too
 */

export const getModulesAndFeaturesFromSubscriptions = (
  subscribed = [],
  activeSubmodules = []
) => {
  return MODULE_MAP.reduce((activeModuleList, currentModule) => {
    if (subscribed.includes(currentModule.id)) {
      // remove any inactive features for that module
      const updatedModule = {
        ...currentModule,
        subRoutes: currentModule.subRoutes.filter((sr) =>
          activeSubmodules.includes(sr.id)
        ),
      };

      activeModuleList.push(updatedModule);
    }
    return activeModuleList;
  }, []);
};
