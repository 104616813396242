import SpainFlagIcon from "../CommonComponents/Icons/SpainFlag";
import UsFlagIcon from "../CommonComponents/Icons/UsFlag";

export const languagesMenu = [
  {
    key: "en",
    menuDisplay: "English",
    translationKey: "english",
    icon: UsFlagIcon
  },
  {
    key: "es",
    menuDisplay: "Spanish",
    translationKey: "spanish",
    icon: SpainFlagIcon
  },
];

export default languagesMenu;
