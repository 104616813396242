import React from "react";
import classNames from "classnames";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { ThemeProvider } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";
import theme from "./theme";

const PREFIX = "Button";

const classes = {
  button: `${PREFIX}-button`,
};

const StyledButton = styled(MuiButton)(() => ({
  [`&.${classes.button}`]: {
    borderWidth: 2,
    padding: "5px 15px",
    "&:hover": {
      borderWidth: 2,
    },
  },
}));

const Button = ({ className, onClick, title, variant, dataCyAtt }) => {
  return (
    <ThemeProvider theme={theme}>
      <StyledButton
        color="primary"
        className={classNames(classes.button, className)}
        onClick={onClick}
        variant={variant}
        data-cy={dataCyAtt}
      >
        {title}
      </StyledButton>
    </ThemeProvider>
  );
};

export default Button;

Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.string,
  title: PropTypes.string.isRequired,
  dataCyAtt: PropTypes.string,
};

Button.defaultProps = {
  className: null,
  variant: "text",
  dataCyAtt: "",
};
