import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

const PREFIX = "Header";

const classes = {
  appbarRoot: `${PREFIX}-appbarRoot`,
  toolbar: `${PREFIX}-toolbar`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.appbarRoot}`]: {
    backgroundColor: "#ffffff",
    color: "#000000",
    flexDirection: "row",
    zIndex: 5,
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      marginLeft: 256,
      width: `calc(100% - 256px)`,
    },
    [theme.breakpoints.up("xl")]: {
      marginLeft: 296,
      width: `calc(100% - 296px)`,
    },
    "@media print": {
      boxShadow: "none",
    },
  },
  [`& .${classes.toolbar}`]: {
    flexGrow: 1,
    minHeight: theme.spacing(6),
    marginLeft: 15,
    [theme.breakpoints.up("lg")]: {
      marginLeft: 0,
    },
    [theme.breakpoints.up("xxl")]: {
      minHeight: theme.spacing(10),
    },
    "@media print": {
      padding: 0,
      minHeight: 60,
      height: 60,
    },
  },
}));

const Header = ({ title, dataCyAtt, children }) => (
  <Root>
    <AppBar
      elevation={1}
      position="fixed"
      color="secondary"
      classes={{ colorSecondary: classes.appbarRoot }}
    >
      <Toolbar className={classes.toolbar}>
        <Typography data-cy={dataCyAtt} variant="h5">
          {title}
        </Typography>
        {children}
      </Toolbar>
    </AppBar>
    <div className={classes.toolbar}></div>
  </Root>
);

export default Header;

Header.propTypes = {
  children: PropTypes.object,
  dataCyAtt: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

Header.defaultProps = {
  dataCyAtt: "header-title",
};
