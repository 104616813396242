import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import useAccordionStyles from "./useAccordionStyles";

const PREFIX = "UserAccordion";

const classes = {
  avatarImage: `${PREFIX}-avatarImage`,
  listRoot: `${PREFIX}-listRoot`,
  logout: `${PREFIX}-logout`,
};

const StyledAccordion = styled(Accordion)(() => ({
  [`& .${classes.avatarImage}`]: {
    width: 32,
    height: 32,
    marginRight: 9,
  },

  [`& .${classes.listRoot}`]: {
    width: "100%",
    padding: 0,
  },

  [`& .${classes.logout}`]: {
    height: 48,
    cursor: "pointer",
    color: "#fff",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

const UserAccordion = ({ username, avatarSrc, logoutFn }) => {
  const accordionClasses = useAccordionStyles();
  const { t } = useTranslation();

  return (
    <StyledAccordion classes={{ root: accordionClasses.root }}>
      <AccordionSummary
        classes={{
          root: accordionClasses.summary,
          content: accordionClasses.summaryContent,
          expandIconWrapper: accordionClasses.summaryIcon,
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Avatar className={classes.avatarImage} src={avatarSrc} />
        <Typography>{username}</Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: accordionClasses.details }}>
        <List component="div" classes={{ root: classes.listRoot }}>
          <ListItem onClick={logoutFn} button>
            <ListItemText
              inset
              primary={t("log-out")}
              primaryTypographyProps={{
                style: { fontSize: "14px", color: "#AFB5B9" },
              }}
            />
          </ListItem>
        </List>
      </AccordionDetails>
    </StyledAccordion>
  );
};
export default UserAccordion;

UserAccordion.propTypes = {
  username: PropTypes.string.isRequired,
  avatarSrc: PropTypes.string.isRequired,
  logoutFn: PropTypes.func.isRequired,
};
