import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Divider from "@mui/material/Divider";
import ModuleAccordion from "./ModuleAccordion";
import UserAccordion from "./UserAccordion";
import LanguagesAccordion from "./LanguagesAccordion";
import Typography from "@mui/material/Typography";
import { ReactComponent as CainthusLogo } from "../../CommonComponents/Icons/CainthusLogo.svg";
import { useFlags } from "launchdarkly-react-client-sdk";

const PREFIX = "ResponsiveDrawer";

const classes = {
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  typography: `${PREFIX}-typography`,
  divider: `${PREFIX}-divider`,
  companyBanner: `${PREFIX}-companyBanner`,
  logoWrapper: `${PREFIX}-logoWrapper`,
  drawerIcon: `${PREFIX}-drawerIcon`,
};

const dividerStyles = {
  marginBottom: 8,
  backgroundColor: "#8bc5cb",
  opacity: 0.19,
};

const Root = styled("nav")(({ theme }) => ({
  [`&.${classes.drawer}`]: {
    background: "#374851",

    [theme.breakpoints.up("lg")]: {
      width: smallDrawerWidth,
      flexShrink: 0,
    },
    [theme.breakpoints.up("xl")]: {
      width: largeDrawerWidth,
      flexShrink: 0,
    },
  },
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  [`& .${classes.drawerPaper}`]: {
    background: "#374851",
    width: smallDrawerWidth,
    overflowX: "hidden",
    overflowY: "auto",
    [theme.breakpoints.up("xl")]: {
      width: largeDrawerWidth,
      flexShrink: 0,
    },
  },

  [`& .${classes.drawerIcon}`]: {
    marginLeft: 60,
  },
}));

const largeDrawerWidth = 296;
const smallDrawerWidth = 256;

const listElements = [
  "navigation-list",
  "navigation-list-item",
  "navigation-list-item-text",
];

const BANNERPREFIX = "CompanyBanner";

const bannerClasses = {
  divider: `${BANNERPREFIX}-divider`,
  companyBanner: `${BANNERPREFIX}-companyBanner`,
  logoWrapper: `${BANNERPREFIX}-logoWrapper`,
};

const StyledCompanyBanner = styled("nav")(() => ({
  [`& .${bannerClasses.divider}`]: {
    ...dividerStyles,
  },

  [`& .${bannerClasses.companyBanner}`]: {
    height: 64,
    width: "calc(100% - 16px)",
    marginLeft: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  [`& .${bannerClasses.logoWrapper}`]: {
    height: 27,
    width: 144,
  },
}));

const CompanyBanner = ({ children }) => {
  return (
    <StyledCompanyBanner>
      <div className={bannerClasses.companyBanner}>
        <div className={bannerClasses.logoWrapper}>
          <CainthusLogo />
        </div>
        {children}
      </div>
      <Divider classes={{ root: bannerClasses.divider }} />
    </StyledCompanyBanner>
  );
};

const ResponsiveDrawer = ({ modules, open, user, toggleDrawer }) => {
  const { logout } = useAuth0();
  const history = useHistory();

  // history won't change so useful for the initial route
  const initialPath = history.location.pathname;
  const appModules = modules.filter((module) => module.id !== "settings");
  const settings = modules.filter((module) => module.id === "settings");

  const modulesDrawer = (
    <div>
      {appModules.map((module) => (
        <ModuleAccordion
          key={module.id}
          title={module.name}
          titleTranslationKey={
            module.translationKeyNavOnly || module.translationKey
          }
          baseRoute={module.baseRoute}
          routes={module.subRoutes}
          icon={module.icon}
          exact={module.exact}
          defaultExpanded={
            module.subRoutes.length > 0 &&
            initialPath.includes(module.baseRoute)
          }
        />
      ))}
    </div>
  );

  const settingsDrawer = (
    <div>
      <Divider style={dividerStyles} />
      <Typography sx={{ color: "#ffffff", marginLeft: "16px" }}>
        {user?.farm?.name}
      </Typography>

      <LanguagesAccordion />

      {settings.map((module) => (
        <ModuleAccordion
          key={module.id}
          title={module.name}
          titleTranslationKey={module.translationKey}
          baseRoute={module.baseRoute}
          routes={module.subRoutes}
          icon={module.icon}
          exact={module.exact}
          defaultExpanded={initialPath.includes(module.baseRoute)}
        />
      ))}
      <UserAccordion
        avatarSrc={user.picture}
        username={user.name}
        logoutFn={() => logout({ returnTo: window.location.origin })}
      />
    </div>
  );

  const onClick = (e) => {
    // If list item was clicked close the menu
    if (listElements.includes(e.target.parentNode.id)) {
      toggleDrawer();
    }
  };

  return (
    <Root
      className={classes.drawer}
      aria-label="mailbox folders"
      data-cy="navigation-drawer"
    >
      <Hidden lgUp implementation="css">
        <div onClick={onClick}>
          <StyledDrawer
            variant="temporary"
            anchor="left"
            open={open}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <CompanyBanner>
              <IconButton
                onClick={toggleDrawer}
                className={classes.drawerIcon}
                size="large"
              >
                <ChevronLeftIcon style={{ color: "#fff" }} />
              </IconButton>
            </CompanyBanner>
            {modulesDrawer}
            <div style={{ flex: "auto" }} />

            {settingsDrawer}
          </StyledDrawer>
        </div>
      </Hidden>
      <Hidden lgDown implementation="css">
        <StyledDrawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <CompanyBanner />
          {modulesDrawer}
          <div style={{ flex: "auto" }} />
          {settingsDrawer}
        </StyledDrawer>
      </Hidden>
    </Root>
  );
};

export default ResponsiveDrawer;

ResponsiveDrawer.propTypes = {
  user: PropTypes.object,
  modules: PropTypes.array,
};
