import { rest } from "msw";

export const handlers = [
  // example of how to add mocked response
  // rest.get(
  //   "url-to-mock",
  //   (req, res, ctx) => {
  //     return res(ctx.status(200), ctx.json({}));
  //   }
  // ),
];
