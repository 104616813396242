import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { NavLink, useLocation } from "react-router-dom";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useAccordionStyles from "./useAccordionStyles";

const PREFIX = "ModuleAccordion";

const classes = {
  accordionSummaryImage: `${PREFIX}-accordionSummaryImage`,
  listRoot: `${PREFIX}-listRoot`,
  inset: `${PREFIX}-inset`,
  navigationLink: `${PREFIX}-navigationLink`,
  navigationLinkSelected: `${PREFIX}-navigationLinkSelected`,
  menuItem: `${PREFIX}-menuItem`,
};

const StyledAccordion = styled(Accordion)(() => ({
  [`& .${classes.accordionSummaryImage}`]: {
    width: 24,
    height: 24,
    marginRight: 16,
  },

  [`& .${classes.listRoot}`]: {
    width: "100%",
    padding: 0,
  },

  [`& .${classes.inset}`]: {
    paddingLeft: 56,
  },

  [`& .${classes.navigationLink}`]: {
    minHeight: "32px",
    height: "auto",
    paddingTop: "2px",
    paddingBottom: "2px",
    color: "#AFB5B9",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.08)",
    },
  },

  [`& .${classes.navigationLinkSelected}`]: {
    background: "#008389",
    color: "#fff",
    "&:hover": {
      background: "#008389",
    },
  },

  [`& .${classes.menuItem}`]: {
    display: "flex",
    padding: "12px 16px",
    minHeight: 48,
  },
}));

const ModuleAccordion = ({
  title,
  titleTranslationKey,
  icon: Icon,
  baseRoute,
  routes,
  defaultExpanded,
  exact,
}) => {
  const accordionClasses = useAccordionStyles();
  const location = useLocation();
  const isCurrentModule = location.pathname.includes(baseRoute);

  const { t } = useTranslation();

  return (
    <StyledAccordion
      classes={{ root: accordionClasses.root }}
      defaultExpanded={defaultExpanded}
    >
      {routes.length === 0 ? (
        <div className={classes.menuItem}>
          <Icon
            className={`${classes.accordionSummaryImage} ${
              isCurrentModule ? accordionClasses.currentRoute : ""
            }`}
            style={{ color: isCurrentModule ? "#65b2b9" : "#AFB5B9" }}
          />
          <Link
            sx={{ "&:hover": { textDecoration: "none" } }}
            to={baseRoute}
            underline="hover"
            component={RouterLink}
            id="navigation-list-item"
            data-cy={"Navigation " + title + " Accordion"}
          >
            <Typography
              style={{
                color: isCurrentModule ? "#8BC5CB" : "#AFB5B9",
              }}
            >
              {titleTranslationKey ? t(titleTranslationKey) : title}
            </Typography>
          </Link>
        </div>
      ) : (
        <AccordionSummary
          classes={{
            root: accordionClasses.summary,
            expanded: accordionClasses.summaryExpanded,
            content: accordionClasses.summaryContent,
            expandIconWrapper: accordionClasses.summaryIcon,
          }}
          expandIcon={routes.length > 0 && <ExpandMoreIcon />}
          data-cy={"Navigation " + title + " Accordion"}
        >
          <Icon
            className={`${classes.accordionSummaryImage} ${
              isCurrentModule ? accordionClasses.currentRoute : ""
            }`}
            style={{ color: isCurrentModule ? "#65b2b9" : "#AFB5B9" }}
          />

          <Typography
            style={{ color: isCurrentModule ? "#8BC5CB" : "#AFB5B9" }}
          >
            {titleTranslationKey ? t(titleTranslationKey) : title}
          </Typography>
        </AccordionSummary>
      )}

      <AccordionDetails classes={{ root: accordionClasses.details }}>
        <List
          component="div"
          classes={{ root: classes.listRoot }}
          id="navigation-list"
        >
          {routes.map((route) => (
            <ListItem
              exact={exact}
              key={route.path}
              component={NavLink}
              to={`${baseRoute}${route.path}`}
              className={classes.navigationLink}
              activeClassName={classes.navigationLinkSelected}
              id="navigation-list-item"
            >
              <ListItemText
                inset
                data-cy={"Navigation " + title + " Accordion " + route.name}
                primary={
                  route.translationKey ? t(route.translationKey) : route.name
                }
                id="navigation-list-item-text"
                primaryTypographyProps={{ variant: "subtitle2" }}
              />
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </StyledAccordion>
  );
};
export default ModuleAccordion;

ModuleAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  titleTranslationKey: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  baseRoute: PropTypes.string.isRequired,
  exact: PropTypes.bool.isRequired,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ),
  defaultExpanded: PropTypes.bool.isRequired,
};
