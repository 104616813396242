import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import useAccordionStyles from "./useAccordionStyles";
import languagesMenu from "../../i18n/LanguagesMenuConst";
import i18n from "../../i18n/config";

const PREFIX = "LanguagesAccordion";

const classes = {
  iconContainer: `${PREFIX}-iconContainer`,
  icon: `${PREFIX}-icon`,
  selectedIcon: `${PREFIX}-selectedIcon`,
  listItemText: `${PREFIX}-listItemText`,
};

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  [`& .${classes.iconContainer}`]: {
    width: "25px",
    height: "25px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  [`& .${classes.icon}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "4px",
  },

  [`& .${classes.selectedIcon}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "7px",
    marginRight: "16px",
  },

  [`& .${classes.listItemText}`]: {
    paddingLeft: "30px",
    [theme.breakpoints.up("xl")]: {
      fontSize: `calc(100% - 296px)`,
    },
  },
}));

const LanguagesAccordion = () => {
  const { t } = useTranslation();

  const accordionClasses = useAccordionStyles();

  const filterSelectedLang = (key) => {
    return languagesMenu.filter((lang) => lang.key === key)[0];
  };

  const [selectedLang, setSelectedLang] = useState(
    localStorage.getItem("selectedLanguage")
      ? filterSelectedLang(localStorage.getItem("selectedLanguage"))
      : filterSelectedLang("en")
  );

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("selectedLanguage", lng);
    setLanguageMenu(lng);
  };

  const setLanguageMenu = (lng) => {
    setSelectedLang(languagesMenu.filter((lang) => lang.key === lng)[0]);
  };

  const filteredLangMenu = languagesMenu.filter(
    (lang) => lang.key !== selectedLang.key
  );

  return (
    <StyledAccordion
      classes={{ root: accordionClasses.root }}
      data-cy="Languages Parent Accordion"
    >
      <AccordionSummary
        classes={{
          root: accordionClasses.summary,
          content: accordionClasses.summaryContent,
          expandIconWrapper: accordionClasses.summaryIcon,
        }}
        expandIcon={<ExpandMoreIcon />}
        data-cy={"Navigation Languages Accordion"}
      >
        <div>
          {selectedLang && selectedLang.key && (
            <selectedLang.icon
              className={classes.selectedIcon}
              width="24px"
              height="24px"
            />
          )}
        </div>

        <Typography>{t(selectedLang.translationKey)}</Typography>
      </AccordionSummary>
      <AccordionDetails
        classes={{ root: accordionClasses.details }}
        data-cy="Languages Accordion Expanded List"
      >
        <List component="div" classes={{ root: classes.listRoot }}>
          {filteredLangMenu.map((lang) => {
            return (
              <ListItem
                key={lang.key}
                data-cy={
                  "Languages Accordion Expanded List Item " + lang.menuDisplay
                }
                onClick={() => changeLanguage(lang.key)}
                button
              >
                <div className={classes.icon}>
                  {
                    <lang.icon
                      className={classes.icon}
                      width="24px"
                      height="24px"
                    />
                  }
                </div>
                <ListItemText
                  inset
                  primary={t(lang.translationKey)}
                  id={lang.menuDisplay + " navigation-list-item-text"}
                  primaryTypographyProps={{ variant: "subtitle2" }}
                  className={classes.listItemText}
                />
              </ListItem>
            );
          })}
        </List>
      </AccordionDetails>
    </StyledAccordion>
  );
};
export default LanguagesAccordion;
